import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import ThankYou from "../../../components/ConversionPath/demo-requested";
// import Image from '../../components/image';
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import { restaurantTestimonials } from "../../../data/restaurant-data";

export default function ThankYouPafe() {
  return (
    <Layout isLanding phoneNumber="+8005640174" phoneText="(800) 654-0174">
      <SEO title="Thank you - your demo request has been received | SpotOn " />

      <ThankYou
        phoneNumber="+8005640174"
        phoneText="(800) 654-0174"
        thankYouSubheader="You can also call us at"
      />

      <TestmonialReviews sectionData={restaurantTestimonials} />
    </Layout>
  );
}
